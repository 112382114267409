export default {
  useLocalStorage: true,
  useNavigator: false,
  locale: process.env.REACT_APP_LOCALE,
  api: process.env.REACT_APP_API || 'https://api.nd51-dev.koffi.vn',
  // Settings
  enableShowBackendError: process.env.REACT_APP_SHOW_BACKEND_ERROR === 'true',
  requireBasicAuth: process.env.REACT_APP_ENABLE_BASIC_AUTH === 'true',
  publicAPIs: ['/sessions/login', '/invoices/code/(.*)', '/contact-us', '/master-invoice-templates/public'],
  maxExportRetries: process.env.REACT_APP_MAX_EXPORT_RETRIES || 20,
  firebaseConfig: {
    koffi: {
      apiKey: 'AIzaSyCPgG5DgZGnF4lBunUJ-CmHsxeOkeil4X8',
      authDomain: 'zivi-dev.firebaseapp.com',
      databaseURL: 'https://zivi-dev.firebaseio.com',
      projectId: 'zivi-dev',
      storageBucket: 'zivi-dev.appspot.com',
      messagingSenderId: '305042458639',
      appId: '1:305042458639:web:0b001d7c41d071dfdd7b7a',
      measurementId: 'G-1TPK4X2ZHW'
    },
    uat: {
      apiKey: 'AIzaSyCPgG5DgZGnF4lBunUJ-CmHsxeOkeil4X8',
      authDomain: 'zivi-dev.firebaseapp.com',
      databaseURL: 'https://zivi-dev.firebaseio.com',
      projectId: 'zivi-dev',
      storageBucket: 'zivi-dev.appspot.com',
      messagingSenderId: '305042458639',
      appId: '1:305042458639:web:bc96438686bbbc10dd7b7a',
      measurementId: 'G-YK4J2F2HM7'
    },
    nd51: {
      apiKey: 'AIzaSyCPgG5DgZGnF4lBunUJ-CmHsxeOkeil4X8',
      authDomain: 'zivi-dev.firebaseapp.com',
      databaseURL: 'https://zivi-dev.firebaseio.com',
      projectId: 'zivi-dev',
      storageBucket: 'zivi-dev.appspot.com',
      messagingSenderId: '305042458639',
      appId: '1:305042458639:web:2e2cc3395691cb21dd7b7a',
      measurementId: 'G-WHJHX56M14'
    },
    'nd51-dev': {
      apiKey: 'AIzaSyCPgG5DgZGnF4lBunUJ-CmHsxeOkeil4X8',
      authDomain: 'zivi-dev.firebaseapp.com',
      databaseURL: 'https://zivi-dev.firebaseio.com',
      projectId: 'zivi-dev',
      storageBucket: 'zivi-dev.appspot.com',
      messagingSenderId: '305042458639',
      appId: '1:305042458639:web:2e2cc3395691cb21dd7b7a',
      measurementId: 'G-WHJHX56M14'
    },
    dev: {
      apiKey: 'AIzaSyCPgG5DgZGnF4lBunUJ-CmHsxeOkeil4X8',
      authDomain: 'zivi-dev.firebaseapp.com',
      databaseURL: 'https://zivi-dev.firebaseio.com',
      projectId: 'zivi-dev',
      storageBucket: 'zivi-dev.appspot.com',
      messagingSenderId: '305042458639',
      appId: '1:305042458639:web:2e2cc3395691cb21dd7b7a',
      measurementId: 'G-WHJHX56M14'
    }
  }
};
